<template>
  <div>
    <router-view/>  
  </div>
</template>

<style scope>
  .dataTable {
    position: relative;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
</style>

<script>

export default {
  name: 'App',
  provide: {
    APIURL: process.env.VUE_APP_API_URL,
  }
}
</script>
