import ApiService from "@/core/services/api.service";

// action types
export const IMAGE_FOLDER = "ImageFolder";

// mutation types
export const SET_IMAGE_FOLDER = "setImageFolder";

const state = {
  ImageFolder: [],
};

const getters = {
  getImageFolder(state) {
    return state.ImageFolder;
  }
};

const actions = {
  [IMAGE_FOLDER](context) {
    return new Promise(resolve => {
      ApiService.query(
        "/Admin/v1/ImageFolder",
        {
          params: {}
        }
      ).then(response => {
        if (response.status == 200) {
          context.commit(SET_IMAGE_FOLDER, response.data.data);
          resolve(response);
        }
      });
    });
  },
};

const mutations = {
  [SET_IMAGE_FOLDER](state, data) {
    state.ImageFolder = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
