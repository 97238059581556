import i18n from "@/core/plugins/vue-i18n";
import Swal from "sweetalert2";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";

const Common = {
  baseURL: process.env.VUE_APP_API_URL,
  AxiosHandle(data) {
    let SwalSetting = {
      heightAuto: false,
      confirmButtonText: i18n.global.t("COMMON.CONFIRM")
    };
    switch (data.status) {
      case 200:
        SwalSetting.title = i18n.global.t("COMMON.SUCCESS");
        SwalSetting.icon = "success";
        SwalSetting.html = data.data.message;
        break;
      case 401:
        SwalSetting.title = i18n.global.t("COMMON.ERROR");
        SwalSetting.icon = "error";
        SwalSetting.html = data.data.message;
        JwtService.destroyToken();
        router.push({ name: "Login" });
        break;
      case 402:
        SwalSetting.title = i18n.global.t("COMMON.ERROR");
        SwalSetting.icon = "error";
        SwalSetting.html = data.data.message;
        break;
      case 403:
        SwalSetting.title = i18n.global.t("COMMON.ERROR");
        SwalSetting.icon = "error";
        SwalSetting.html = data.data.message;
        break;
      case 422:
        SwalSetting.title = i18n.global.t("COMMON.WARNING");
        SwalSetting.icon = "warning";
        SwalSetting.html = data.data.message;
        break;
      case 500:
        SwalSetting.title = i18n.global.t("COMMON.ERROR");
        SwalSetting.icon = "error";
        SwalSetting.html = i18n.global.t("COMMON.SYSTEM-ERROR");
        break;
      default:
        SwalSetting.title = i18n.global.t("COMMON.ERROR");
        SwalSetting.icon = "error";
        SwalSetting.html = i18n.global.t("COMMON.UNDEFIND-ERROR") + ":" + data.status;
        break;
    }
    Swal.fire(SwalSetting);
  },
  DataNoHandle(datas, page, limit) {
    var no = (page - 1) * limit + 1;
    for (var data in datas) {
      datas[data]["no"] = no;
      no++;
    }
    return datas;
  },
  SortHandle(options) {
    let extend = "?";
    extend += "page=" + options.page;
    extend += "&limit=" + options.itemsPerPage;
    if (options.sortBy.length > 0) {
      let sort = "asc";
      if (options.sortDesc[0]) {
        sort = "desc";
      }
      extend += "&orderby=" + options.sortBy[0] + "&sort=" + sort;
    }
    return extend;
  },
  SortHandleObject(options) {
    let extend = {
      page: options.page,
      limit: options.itemsPerPage,
    };
    if (options.sortBy.length > 0) {
      let sort = "asc";
      if (options.sortDesc[0]) {
        sort = "desc";
      }
      extend.orderby = options.sortBy[0];
      extend.sort = sort;
    }
    return extend;
  },
  TimeFormat(datetime) {
    let t = new Date(datetime);
    return `${t.getFullYear()}-${(t.getMonth()+1).toString().padStart(2,0)}-${t.getDate().toString().padStart(2,0)} ${t.getHours().toString().padStart(2,0)}:${t.getMinutes().toString().padStart(2,0)}:${t.getSeconds().toString().padStart(2,0)}`;
    // if(typeof(datetime) != 'undefined') {
    //   let t = datetime.split('.');
    //   return t[0].replace("T"," ");  
    // }
    // return "";
    // return t.toLocaleString();
  },
  ObjectNoDataHandle(data) {
    let tmp = {};
    Object.keys(data).forEach((key) => {
      if(data[key]!=""){
        tmp[key] = data[key]
      }
    });
    return tmp;
  },
  ImageUrlHandle(item){
    return `${this.baseURL}/storage/${item.directory}/${item.filename}.${item.extension}`;
  },
  JsonToForm(data,formdata,index) {
    Object.keys(data).map(item => {
      if(typeof data[item] == "object" && data[item] && data[item].constructor.name != "File"){
        this.JsonToForm(data[item],formdata, ((index)?`${index}[${item}]`:item));
      }else{
        if(data[item] != undefined && !(typeof data[item] == 'string' && !!data[item].match(/;base64,/))){
          if(index){
            formdata.append(`${index}[${item}]`,data[item]);
          }else{
            formdata.append(item,data[item]);
          }  
        }
      }
    });
    return formdata;
  },
  MakeForm(data) {
    let form = new FormData();
    return this.JsonToForm(data,form);
  },
  StatusSwal(status) {
    return Swal.fire({
      title: i18n.global.t("COMMON.WARNING"),
      html: ((!status)?i18n.global.t("COMMON.CONFIRM-ENABLE"):i18n.global.t("COMMON.CONFIRM-DISABLE")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: ((!status)?"green":"red"),
      confirmButtonText: ((!status)?i18n.global.t("COMMON.ENABLE"):i18n.global.t("COMMON.DISABLE")),
      cancelButtonText: i18n.global.t("COMMON.CANCEL")
    });
  },
  ConfirmSwal() {
    return Swal.fire({
      title: i18n.global.t("COMMON.WARNING"),
      html: "確定要通過嗎？",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "確定",
      cancelButtonText: i18n.global.t("COMMON.CANCEL")
    });
  },
  DeleteSwal() {
    return Swal.fire({
      title: i18n.global.t("COMMON.WARNING"),
      html: i18n.global.t("COMMON.CONFIRM-DELETE"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: i18n.global.t("COMMON.DELETE"),
      cancelButtonText: i18n.global.t("COMMON.CANCEL")
    });
  }
};

export default Common;
