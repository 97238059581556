import { createRouter, createWebHashHistory } from 'vue-router';
import store from "@/core/services/store";

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import("@/views/layouts/Layout"),
		children: [
			{
				path: "/Dashboard",
				name: "Dashboard",
				component: () => import("@/views/Dashboard")
			},
      {
        path: "image-manage",
        name: "image-manage",
        component: () => import("@/views/web/ImageManage")
      },
      {
        path: "plugin",
        name: "plugin",
        component: () => import("@/views/Plugin")
      },
			{
        path: "/System",
        name: "System",
        component: () => import("@/views/system/System"),
        children: [
          {
            path: "Managers",
            name: "Managers",
            component: () => import("@/views/system/Managers")
          },
          {
            path: "role",
            name: "role",
            component: () => import("@/views/system/RoleManage")
          },
          {
            path: "login-record",
            name: "login-record",
            component: () => import("@/views/system/LoginRecord")
          },
          {
            path: "operate-record",
            name: "operate-record",
            component: () => import("@/views/system/OperateRecord")
          },
          {
            path: "site-manage",
            name: "site-manage",
            component: () => import("@/views/system/WebManage.vue")
          },
          {
            path: "Curreny-settings",
            name: "Curreny-settings",
            component: () => import("@/views/system/CurrenySettings.vue")
          },
          {
            path: "Gold-flow-manage",
            name: "Gold-flow-manage",
            component: () => import("@/views/system/GoldFlowManage.vue")
          },
          {
            path: "seo-manage",
            name: "seo-manage",
            component: () => import("@/views/system/SeoManage.vue")
          },
          {
            path: "services-manage",
            name: "services-manage",
            component: () => import("@/views/system/ServicesManage.vue")
          },
          {
            path: "email-manage",
            name: "email-manage",
            component: () => import("@/views/system/EmailManage.vue")
          },
          {
            path: "email-template",
            name: "email-template",
            component: () => import("@/views/email/EmailTemplate.vue")
          },
          {
            path: "email-log",
            name: "email-log",
            component: () => import("@/views/email/EmailLog.vue")
          },
          {
            path: "commodity-settings",
            name: "commodity-settings",
            component: () => import("@/views/web/CommoditySettings.vue")
          },
          {
            path: "seo-key",
            name: "seo-key",
            component: () => import("@/views/system/SeoKeyManage.vue")
          },
        ]
      },
      {
        path: "/Home",
        name: "Home",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "home-manage",
            name: "home-manage",
            component: () => import("@/views/web/HomeManage")
          },
          {
            path: "dealer-home-manage",
            name: "dealer-home-manage",
            component: () => import("@/views/web/DealerManage.vue")
          },
          {
            path: "content-manage",
            name: "content-manage",
            component: () => import("@/views/web/ContentManage.vue")
          },
          {
            path: "computer-standard",
            name: "computer-standard",
            component: () => import("@/views/computer/Computer.vue")
          }
        ]
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "faq-category",
            name: "faq-category",
            component: () => import("@/views/faq/Category.vue")
          },
          {
            path: "faq",
            name: "faq",
            component: () => import("@/views/faq/Faq.vue")
          }
        ]
      },
      {
        path: "/Member",
        name: "Member",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "member-list",
            name: "member-list",
            component: () => import("@/views/member/MemberList")
          },
          {
            path: "member-tag",
            name: "member-tag",
            component: () => import("@/views/member/MemberTag")
          },
          {
            path: "dealer-manage",
            name: "dealer-manage",
            component: () => import("@/views/member/DealerList")
          },
          {
            path: "waiter-manage",
            name: "waiter-manage",
            component: () => import("@/views/Demo.vue")
          },
          {
            path: "refer-sale-record",
            name: "refer-sale-record",
            component: () => import("@/views/Demo.vue")
          },
          {
            path: "refer-member-record",
            name: "refer-member-record",
            component: () => import("@/views/member/ReferMemberRecord.vue")
          },
          {
            path: "industry-manage",
            name: "industry-manage",
            component: () => import("@/views/member/Industry")
          },
          {
            path: "profession-manage",
            name: "profession-manage",
            component: () => import("@/views/member/Profession")
          },
          {
            path: "import",
            name: "member-import",
            component: () => import("@/views/member/Import.vue")
          },
        ]
      },
      {
        path: "/commodity",
        name: "commodity",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "commodity-category",
            name: "commodity-category",
            component: () => import("@/views/product/ProductCategory")
          },
          {
            path: "product-list",
            name: "product-list",
            component: () => import("@/views/product/ProductList")
          },
          {
            path: "commodity-list",
            name: "commodity-list",
            component: () => import("@/views/commodity/CommodityList")
          },
          {
            path: "package-commodity-list",
            name: "package-commodity-list",
            component: () => import("@/views/commodity/PackageCommodityList")
          },
          {
            path: "product-tag",
            name: "product-tag",
            component: () => import("@/views/product/ProductTag")
          },
        ]
      },
      {
        path: "/apply",
        name: "apply",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "apply-manage",
            name: "apply-manage",
            component: () => import("@/views/apply/Apply.vue")
          },
          {
            path: "apply-record",
            name: "apply-record",
            component: () => import("@/views/apply/ApplyRecord.vue")
          }
        ]
      },
      {
        path: "/discount",
        name: "discount",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "discount-manage",
            name: "discount-manage",
            component: () => import("@/views/promo/Promo.vue")
          },
          {
            path: "discount-record",
            name: "discount-record",
            component: () => import("@/views/promo/PromoRecord.vue")
          }
        ]
      },
      {
        path: "/order",
        name: "order",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "order-manage",
            name: "order-manage",
            component: () => import("@/views/order/Order.vue")
          },
          {
            path: "yet-order",
            name: "yet-order",
            component: () => import("@/views/order/YetOrder.vue")
          },
          {
            path: "subscription-manage",
            name: "subscription-manage",
            component: () => import("@/views/order/Subscription.vue")
          },
          {
            path: "device-valid",
            name: "device-valid",
            component: () => import("@/views/order/ActivationCode.vue")
          },
          {
            path: "import",
            name: "order-import",
            component: () => import("@/views/order/Import.vue")
          },
        ]
      },
      {
        path: "/technology",
        name: "technology",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "technology-category",
            name: "technology-category",
            component: () => import("@/views/technology/TechnologyCategory.vue")
          },
          {
            path: "technology-support",
            name: "technology-support",
            component: () => import("@/views/technology/TechnologyList.vue")
          },
          {
            path: "technology-discuss-forum",
            name: "technology-discuss-forum",
            component: () => import("@/views/forum/Forum.vue")
          }
        ]
      },
      {
        path: "/report",
        name: "report",
        component: () => import("@/views/layouts/SubView"),
        children: [
          {
            path: "sale-report",
            name: "sale-report",
            component: () => import("@/views/report/SaleReport.vue")
          },
          {
            path: "refer-report",
            name: "refer-report",
            component: () => import("@/views/report/DealerReport.vue")
          }
        ]
      }
		]
  },
  {
		path: "/Login",
		name: "Login",
		component: () => import("@/views/Login")
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // store.dispatch("startLoadingbar",0);
  if (to.name !== "Login") {
    var loadingbar = setInterval(function(){ store.dispatch("startLoadingbar",(store.getters.getLoadingbar <= 90)?(store.getters.getLoadingbar + 2):store.getters.getLoadingbar)}, 100);
  }
  
  Promise.all([store.dispatch("verifyAuth")])
    .then(() => {
      clearInterval(loadingbar);
      store.dispatch("startLoadingbar", 100).then(()=>{
        setTimeout(function(){store.dispatch("startLoadingbar",0)},1000);  
      });
      if (to.name == "Layout") {
        next({path: "/Dashboard"});
      }
      next();
    })
    .catch(() => {
    if (to.name !== "Login") {
      next({path: "/Login"});
    }else{
      next();
    }
   });
  // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  
});

export default router
