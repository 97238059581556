// China
export const locale = {
  LANG: {
    tw: "繁體中文",
    en: "英文",
    cn: "簡體中文"
  },
  COMMON: {
    member_terms: "會員使用條款",
    bank: "銀行轉帳",
    credit: "信用卡",
    atm: "ATM轉帳",
    creditPeriod: "信用卡(訂閱)",

    ACCOUNT: "帳戶",
    ACTIVATIONCODE: "產品金鑰",
    ADDPRODUCT: "加入產品",
    ALLRECORD: "全部紀錄",
    AMOUNT: "金額",
    APPLYBANNER: "課程封面",
    APPLYCAROUSEL: "課程輪播圖",
    APPLYDESCRIPTION: "課程描述",
    APPLYDOWNLOADS: "課程下載",
    APPLYNUMBER: "課程編號",
    APPLYSTATUS: "課程狀態",
    "ARE-YOU-DISABLE": "你確定要停用?",
    "ARE-YOU-ENABLE": "你確定要啟用?",
    ARTICLECOUNT: "貼文數量",

    BANKACCOUNT: "銀行帳號",
    BANKACCOUNTNAME: "收款戶名",
    BANKACCOUNTPHONE: "收款戶電話",
    BANKACCOUNTADDRESS: "收款戶地址",
    BANKNAME: "銀行名稱",
    BANKBRANCH: "銀行分行",
    BANKADDRESS: "銀行地址",
    SWIFTCODE: "Swift Code",
    BEFORE7DAYS: "過去７日",
    BEFORE30DAYS: "過去30日",
    BEFOREMONTH: "上個月",
    BUYNOW: "立即購買",

    CANCEL: "取消",
    CAPTCHA: "驗證碼",
    CAROUSEL_IMAGE: "輪播圖片",
    CATEGORY: "分類",
    CATEGORYNAME: "分類名稱",
    CHANGEPASSWORD: "變更密碼",
    CHAPTER: "章節",
    CLOSE: "關閉",
    COMMODITYAUTHORIZATION: "機器接收授權",
    COMMODITYAUTHORIZATIONSERVER: "授權伺服器",
    COMMODITYAUTO: "自動分配授權",
    COMMODITYCOUNT: "使用次數",
    COMMODITYCURRENCY: "付費方案",
    COMMODITYDESCRIPTION: "商品簡介",
    COMMODITYFREESTATUS: "免費試用",
    COMMODITYNAME: "商品名稱",
    COMMODITYNUMBER: "商品編號",
    COMMODITYPEOPLE: "人版數",
    COMMODITYSTATUS: "商品狀態",
    COMMODITYTYPE_1: "產品",
    COMMODITYTYPE_2: "課程",
    COMMODITYTYPE_3: "套裝產品",
    CONFIRM: "確定",
    "CONFIRM-ENABLE": "確定要啟用嗎？",
    "CONFIRM-DELETE": "確定要刪除?",
    "CONFIRM-DISABLE": "確定要停用嗎？",
    CONTENT: "內容",
    COUNTRY: "國家",
    COPY: "複製",
    CREATETIME: "建立時間",
    CREATE_AT: "建立時間",
    CUMULATIVEPAYMENTAMOUNT: "累積付款金額",

    DEALERACCOUNT: "經銷商帳號",
    DEALEREMAIL: "經銷商信箱",
    DEALERLOGO: "經銷商標誌",
    DEALERNAME: "經銷商暱稱",
    DEALERPERCENTAGE: "經銷商分潤(%)",
    DEALERSETTINGS: "經銷商設定",
    DEALERSTATUS: "經銷商狀態",
    DELETE: "刪除",
    DELETEPRODUCT: "刪除產品",
    DEVICE: "裝置",
    DEVICENAME: "裝置名稱",
    DISABLE: "停用",
    DOMESTICACCOUNT: "國內帳號",

    ECPAYGOLDSETTINGSFORFRONT: "綠界金流設定(前台)",
    ECPAYGOLDSETTINGSFORBACK: "綠界金流設定(後台)",
    ECPAYPAYMENTBRANCH: "支付方式",
    EDIT: "編輯",
    EDITAPPLY: "編輯課程",
    EDITCATEGORY: "編輯分類",
    EDITCOMMODITY: "選擇商品",
    EDITPRODUCT: "編輯產品",
    EMAIL: "電子郵件",
    EMAILACCOUNT: "Email/帳號",
    ENABLE: "啟用",
    END_DATE: "結束日期",
    END_TIME: "結束時間",
    ERROR: "錯誤",
    EXPORT: "匯出",

    FREE: "免費試用",
    free: "免費試用",
    FILELINK: "檔案連結",
    FILENAME: "檔案名稱",
    FILEPATH: "圖片路徑",
    FOLDERNAME: "資料夾名稱",
    FORGOTPASSWORD: "忘記密碼",
    FOREXACCOUNT: "外匯帳號",

    GENERALSETTINGS: "一般設定",

    I18NNAME: {
      cn: "簡體中文名稱",
      en: "英文名稱",
      tw: "繁體中文名稱",
    },
    IMAGE: "圖片",
    IMAGETYPE: "圖片類型",
    INSERT: "新增",
    IPADDRESS: "IP位置",

    LABELNAME: "標籤名稱",
    LANGUAGE: "語言",
    LAST_LOGIN_AT: "最後登入時間",
    LINK: "連結",
    LOADING: "載入中",
    LOCATION: "所在地區",
    LOGIN: "登入",
    LOGINCOUNT: "登入次數",
    LOGINIP: "登入IP",
    LOGINRECORD: "登入記錄",
    LOGINTIME: "登入時間",

    MAINCATEGORY: "主分類",
    MAINTITLE: "主標題",
    MAN: "男",
    MEMBEREMAIL: "會員信箱",
    MEMBERNAME: "會員暱稱",
    MEMBERTOTAL: "會員總數",
    MONTH: "月",
    MONTH0: "月",
    MONTH1: "1個月",
    MONTH3: "3個月",
    MONTH6: "6個月",
    MONTH12: "12個月",
    MOREINFO: "更多資訊",

    NAME: "名稱",
    NEXTPAYMENTDATE: "下次付款日",
    NO: "否",

    OLDPASSWORD: "舊密碼",
    ONCE: "永久授權",
    OPERATE: "操作",
    OPERATEITEM: "操作事項",
    OPERATETIME: "操作時間",
    ORDERAMOUNT: "訂單金額",
    ORDERCOUNT: "商品小計",
    ORDERNO: "訂單號",
    ORDERNUMBER: "訂單編號",
    ORDERPROMO: "優惠代碼折抵",
    ORDERPSTATUS: "訂單狀態",
    ORDERPTOTAL: "訂單總金額",
    ORDERSTATUS: "訂單狀態",
    ORDERTIME: "訂單時間",
    ORDERTYPE: "訂單類型",

    PASSWORD: "密碼",
    PASSWORDCONFIRM: "再次確認密碼",
    PAYMENTEDNUMBER: "已付款次數",
    PERMISSIONSETTING: "權限設定",
    PLEASESELECT: "請選擇",
    PRICE: "價格",
    PRODUCT: "產品",
    PRODUCTBANNER: "產品封面",
    PRODUCTCAROUSEL: "產品輪播圖",
    PRODUCTCOUNT: "產品數量",
    PRODUCTDESCRIPTION: "產品簡介",
    PRODUCTDOWNLOADS: "產品下載",
    PRODUCTIMAGE: "產品圖片",
    PRODUCTNAME: "商品名稱",
    PRODUCTNUMBER: "商品編號",
    PRODUCTSTANDARD: "商品規格",
    PRODUCTSTATUS: "產品狀態",
    PRODUCTTAG: "產品標籤",
    PRODUCTTYPE: "產品類型",
    PROFITAMOUNT: "獲利金額",
    PROMOAMOUNT: "優惠折扣",
    PROMOCODE: "優惠代碼",
    PROMOENDDATE: "優惠結束日",
    PROMONAME: "優惠名稱",
    PROMOSTARTDATE: "優惠開始日",
    PROMOSTATUS: "優惠狀態",
    PUTOFF: "下架",
    PUTON: "上架",
    privacy: "隱私權政策",

    QUANTITY: "數量",

    RATE: "匯率",
    REFER: "推薦人",
    REFERRER: "推薦人",
    RELATEDLINK: "相關連結",

    SAVE: "儲存",
    SEARCH: "查詢",
    SECONDTITLE: "副標題",
    SELECTIMAGE: "選擇圖片",
    SELECTLANAGUAGE: "選擇語言",
    SELECTLANGUAGE: "選擇語言",
    SEQUENCE: "序",
    SERIALNUMBER: "編號",
    SETAPPLY: "設定課程",
    SETBANNER: "設定封面",
    SETCAROUSEL: "設置輪播圖",
    SETCATEGORY: "設定分類",
    SETCOMMODITY: "設定商品",
    SETCONTENT: "設定內容",
    SETDOWNOLAD: "設定檔案下載",
    SETPRODUCT: "設定商品",
    SETTITLE: "設置標題",
    SETVIDEO: "設定影片",
    SEX: "性別",
    SORT: "排序",
    SOURCEIP: "來源IP",
    START_DATE: "開始日期",
    START_TIME: "開始時間",
    STATUS: "狀態",
    SUBCATEGORY: "子分類",
    SUBTOTAL: "小計",
    SUCCESS: "成功",
    SUBSCRIPTIONAMOUNT: "訂閱金額",
    SUBSCRIPTIONEXPIRATIONDATE: "訂閱到期日",
    SUBSCRIPTIONNUMBER: "訂閱次數",
    SUBSCRIPTIONPLAN: "訂閱方案",
    "SYSTEM-ERROR": "系統錯誤",

    TAG: "標籤",
    TAGNAME: "標籤名稱",
    TECHNOLOGYCATEGORY: "主題分類",
    THISMONTH: "本月",
    TITLE: "標題",
    TOTAL: "總計",
    TOTALCONSUMPTION: "消費總金額",
    TYPE: "類型",
    terms: "使用條款",

    "UNDEFIND-ERROR": "未知的錯誤",
    UP_TIME_RANGE: "上架區間",
    UPDATE: "更新",
    UPDATE_AT: "更新時間",
    USETIME: "使用時間",
    USEVERSION: "使用版本",

    VERSION: "版本",
    VIDEOLINK: "影片連結",
    VIDEONAME: "影片名稱",

    WARNING: "警告",
    WOMAN: "女",
    
    YES: "是",
  },
  PAGES: {
    CHANGEPASSWORD: {
      NOTICE: "需注意密碼可填入數字及英文字母區分大小寫，請妥善保管！"
    }
  },
  CURRENCY: {
    AED: "阿拉伯聯合大公國迪拉姆",
    ANG: "荷屬安地列斯盾",
    ARS: "阿根廷披索",
    AUD: "澳幣",
    AZN: "亞塞拜然馬納特",
    BAM: "可兌換馬克",
    BBD: "巴貝多元",
    BDT: "孟加拉塔卡",
    BGN: "保加利亞列弗",
    BHD: "巴林第納爾",
    BND: "汶萊元",
    BOB: "玻利維亞幣",
    BRL: "巴西里爾",
    BWP: "波札那普拉",
    CAD: "加幣",
    CHF: "瑞士法郎",
    CLP: "智利披索",
    CNY: "中國人民幣",
    COP: "哥倫比亞披索",
    CZK: "捷克克朗",
    DKK: "丹麥克朗",
    DOP: "多明尼加披索",
    DZD: "阿爾及利亞第納爾",
    EGP: "埃及鎊",
    EUR: "歐元",
    FJD: "斐濟元",
    GBP: "英鎊",
    GHS: "迦納席迪",
    GTQ: "瓜地馬拉格查爾",
    HKD: "港幣",
    HNL: "宏都拉斯倫皮拉",
    HRK: "克羅埃西亞克朗",
    HUF: "匈牙利福林",
    IDR: "印尼盧比",
    ILS: "以色列鎊",
    INR: "印度盧比",
    ISK: "冰島克朗",
    JMD: "牙買加元",
    JOD: "約旦第納爾",
    JPY: "日圓",
    KES: "肯亞先令",
    KRW: "韓圓",
    KWD: "科威特第納爾",
    KYD: "開曼群島元",
    KZT: "哈薩克坦吉",
    LBP: "黎巴嫩鎊",
    LKR: "斯里蘭卡盧比",
    LTL: "立陶宛利塔斯",
    MAD: "摩洛哥迪拉姆",
    MDL: "摩爾多瓦列伊",
    MKD: "馬其頓第納爾",
    MMK: "緬甸元",
    MRO: "茅利塔尼亞烏吉亞",
    MUR: "模里西斯盧比",
    MVR: "馬爾地夫盧非亞",
    MXN: "墨西哥披索",
    MYR: "馬來西亞林吉特",
    NAD: "納米比亞元",
    NGN: "奈及利亞奈拉",
    NIO: "尼加拉瓜科多巴",
    NOK: "挪威克朗",
    NPR: "尼泊爾盧比",
    NZD: "紐西蘭元",
    OMR: "阿曼里亞爾",
    PEN: "秘魯新索爾",
    PGK: "巴布亞紐幾內亞基納",
    PHP: "菲律賓披索",
    PKR: "巴基斯坦盧比",
    PLN: "波蘭新茲羅提",
    PYG: "巴拉圭瓜拉尼",
    QAR: "卡達里亞爾",
    RON: "新羅馬尼亞列伊",
    RSD: "塞爾維亞第納爾",
    RUB: "俄羅斯盧布",
    SAR: "沙烏地里雅",
    SCR: "塞席爾盧比",
    SEK: "瑞典克朗",
    SGD: "新加坡元",
    SLL: "獅子山利昂",
    SRD: "蘇利南元",
    THB: "泰銖",
    TND: "突尼西亞第納爾",
    TRY: "土耳其里拉",
    TTD: "千里達及托巴哥元",
    TWD: "新臺幣",
    TZS: "坦尚尼亞先令",
    UAH: "烏克蘭赫里夫尼亞",
    UGX: "烏干達先令",
    USD: "美元",
    UYU: "烏拉圭披索",
    UZS: "烏茲別克索姆",
    VEF: "委內瑞拉玻利瓦爾",
    VND: "越南盾",
    XAF: "CFA 法郎 BEAC",
    XOF: "CFA 法郎 BCEAO",
    YER: "葉門里亞爾",
    ZAR: "南非蘭特",
    ZMW: "尚比亞夸加",
    TJS: "索莫尼",
    DJF: "吉布地法郎",
    SHP: "聖赫勒拿磅",
    PAB: "巴爾波",
    BYR: "白俄羅斯盧布",
    XPF: "太平洋法郎",
    TMT: "土庫曼馬納特",
    CDF: "剛果法郎",
    SOS: "索馬利亞先令",
    AFN: "阿富汗尼",
    KGS: "吉爾吉斯索姆",
    IRR: "伊朗里亞爾",
    FKP: "福克蘭群島磅",
    GEL: "拉里",
    GIP: "直布羅陀鎊",
    GNF: "幾內亞法郎",
    MNT: "蒙古圖格里克",
    KHR: "瑞爾",
    BMD: "百慕達幣",
    RWF: "盧安達法郎",
    HTG: "海地古德",
    LAK: "寮幣",
    CLF: "UF值",
    WST: "薩摩亞塔拉",
    BTN: "那特倫",
    CVE: "維德角埃斯庫多",
    TOP: "TOP Network",
    KPW: "北韓圜",
    SVC: "薩爾瓦多科朗",
    GGP: "格恩西島鎊",
    ETB: "衣索比亞比爾",
    BZD: "貝里斯元",
    KMF: "葛摩法郎",
    BSD: "巴哈馬幣",
    ERN: "Ethernity Chain",
    ALL: "澳元",
    MZN: "梅蒂卡爾",
    ZWL: "辛巴威幣",
    AOA: "匡撒",
    XDR: "特別提款權",
    AWG: "阿魯巴弗羅林",
    VUV: "萬那杜瓦圖",
    MWK: "夸加",
    LSL: "馬洛蒂",
    LYD: "利比亞第納爾",
    BYN: "白俄羅斯盧布",
    XAG: "銀",
    BIF: "蒲隆地法郎",
    ZMK: "尚比亞克瓦查",
    SYP: "敘利亞鎊敘利亞鎊",
    MGA: "阿里亞里",
    XCD: "東加勒比海幣",
    CRC: "哥斯大黎加科朗",
    SDG: "蘇丹鎊",
    CUC: "古巴可兌換披索",
    CUP: "古巴披索",
    GYD: "蓋亞那幣",
    JEP: "澤西鎊",
    LRD: "賴比瑞亞元",
    LVL: "拉脫維亞拉特",
    AMD: "德拉姆",
    IQD: "伊拉克第納爾",
    GMD: "達拉西",
    IMP: "曼島鎊",
    STD: "杜布拉",
    SZL: "史鍰",
    MOP: "澳門幣",
    SBD: "索羅門群島元"
  },
  PRODUCTTYPE: {
    "TYPE_1": "產品",
    "TYPE_2": "課程",
    "TYPE_3": "套裝產品",
  },
  MEMBER: {
    "STATUS_0": "停用",
    "STATUS_1": "啟用",
  },
  FORUM: {
    "STATUS_0": "未解決",
    "STATUS_1": "解決中",
    "STATUS_2": "已解決",
    "ADD-ITEM": "新增問題",
    "EDIT-ITEM": "回覆問題",
    "ENABLE-ITEM": "啟用問題",
    "DISABLE-ITEM": "停用問題",
    "DELETE-ITEM": "刪除問題"
  },
  DEALER: {
    "STATUS_0": "否",
    "STATUS_1": "是",
    "ADD-ITEM": "新增經銷商",
    "EDIT-ITEM": "編輯經銷商",
  },
  ECPAY: {
    STORENUMBER: "商店代號",
    HASHKEY: "HashKey",
    HASHIV: "HashIV",
    BRANCH: {
      BRANCH_0: "信用卡",
      BRANCH_1: "銀聯卡",
      BRANCH_2: "ATM 虛擬帳戶",
    },
    STAGING: {
      STAGING_0: "定期定額",
      STAGING_1: "分3期",
      STAGING_2: "分6期",
      STAGING_3: "分12期",
      STAGING_4: "分24期",
      STAGING_5: "自動續約",
    }
  },
  ORDER: {
    TYPE_1: "新增",
    TYPE_2: "續約",
    TYPE_3: "報名",
    TYPE_4: "取消",
    STATUS_0: "未付款",
    STATUS_1: "已付款",
    STATUS_2: "取消",
    STATUS_3: "已取消",
    STATUS_4: "取消續訂",
    STATUS_5: "待確認",
    DETAIL: "訂單詳情",
    CANCEL: "取消訂閱",
    REAUTH: "自動續訂"
  },
  PROMO: {
    "ADD-ITEM": "新增優惠",
    "EDIT-ITEM": "編輯優惠",
    STATUS_0: "停用",
    STATUS_1: "啟用",
  },
  "TECHNOLOGYCATEGORY": {
    "TABLE-NAME": "問題管理",
    "ADD-ITEM": "新增主章節",
    "EDIT-ITEM": "編輯主章節",
    "ENABLE-CAROUSEL": "啟用問題",
    "DISABLE-CAROUSEL": "停用輪播圖",
    "DELETE-ITEM": "刪除主章節",
  },
  "TECHNOLOGY": {
    "TABLE-NAME": "輪播管理",
    "ADD-ITEM": "新增章節",
    "EDIT-ITEM": "編輯章節",
    "ENABLE-CAROUSEL": "啟用輪播圖",
    "DISABLE-CAROUSEL": "停用輪播圖",
    "DELETE-ITEM": "刪除章節",
    "ADD-VIDELS": "新增影片"
  },
  "HOME-MANAGE": {
    "HOME-SETTINGS": "首頁設定"
  },
  "ROLE": {
    "TABLE-NAME": "輪播管理",
    "ADD-ITEM": "新增角色",
    "EDIT-ITEM": "編輯角色",
    "ENABLE-CAROUSEL": "啟用輪播圖",
    "DISABLE-CAROUSEL": "停用輪播圖",
    "DELETE-ITEM": "刪除輪播圖"
  },
  "HOMEMANAGE": {
    "TABLE-NAME": "輪播管理",
    "ADD-ITEM": "新增設定",
    "EDIT-ITEM": "編輯設定",
    "ENABLE-CAROUSEL": "啟用輪播圖",
    "DISABLE-CAROUSEL": "停用輪播圖",
    "DELETE-ITEM": "刪除設定"
  },
  "HOME-CAROUSEL": {
    "TABLE-NAME": "輪播管理",
    "ADD-ITEM": "新增輪播",
    "EDIT-ITEM": "編輯輪播",
    "ENABLE-CAROUSEL": "啟用輪播圖",
    "DISABLE-CAROUSEL": "停用輪播圖",
    "DELETE-ITEM": "刪除輪播圖"
  },
  "COMPUTERFORMAT-MANAGE": {
    "TABLE-NAME": "電腦規格管理",
    "ADD-ITEM": "新增輪播",
    "EDIT-ITEM": "編輯電腦規格",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除輪播圖"
  },
  "DEALER-MANAGE": {
    "TABLE-NAME": "經銷商列表",
    "ADD-ITEM": "新增經銷商",
    "EDIT-ITEM": "編輯經銷商首頁",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除輪播圖"
  },
  "COMMUNITYFORTUM-MANAGE": {
    "TABLE-NAME": "論壇標籤列表",
    "ADD-ITEM": "新增論壇標籤",
    "EDIT-ITEM": "編輯論壇標籤",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除輪播圖"
  },
  "PRODUCT-LIST": {
    "TABLE-NAME": "商品列表",
    "ADD-ITEM": "新增商品",
    "EDIT-ITEM": "編輯商品",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除商品",
    "ADD-PRODUCTDOWNLOADS": "增加檔案下載"
  },
  "APPLYLIST": {
    "TABLE-NAME": "課程列表",
    "ADD-ITEM": "新增課程",
    "EDIT-ITEM": "編輯課程",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除課程",
    "ADD-APPLYDOWNLOADS": "增加檔案下載"
  },
  "COMMODITY-LIST": {
    "TABLE-NAME": "產品列表",
    "ADD-ITEM": "新增產品",
    "EDIT-ITEM": "編輯產品",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除產品",
    "ADD-PRODUCTDOWNLOADS": "增加檔案下載",
    "IMPORT-ITEM": "匯入",
  },
  "PACKAGE-COMMODITY-LIST": {
    "TABLE-NAME": "商品列表",
    "ADD-ITEM": "新增商品",
    "EDIT-ITEM": "編輯商品",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除商品",
    "ADD-PRODUCTDOWNLOADS": "增加檔案下載"
  },
  "MEMBER-LIST": {
    "TABLE-NAME": "會員列表",
    "ADD-ITEM": "新增會員",
    "EDIT-ITEM": "編輯會員",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除會員"
  },
  "PRODUCT-CATEGORY": {
    "MAIN-CATEGORY": "主分類",
    "SUB-CATEGORY": "子分類",
    "TABLE-NAME": "產品分類列表",
    "ADD-ITEM": "新增主商品分類",
    "EDIT-ITEM": "編輯主商品分類",
    "ENABLE-ITEM": "啟用輪播圖",
    "DISABLE-ITEM": "停用輪播圖",
    "DELETE-ITEM": "刪除輪播圖",
    "ADD-SUB-ITEM": "新增子產品分類",
    "EDIT-SUB-ITEM": "編輯子產品分類",
  },
  "FAQ-CATEGORY": {
    "ADD-ITEM": "新增分類",
    "EDIT-ITEM": "編輯分類",
  },
  "FAQ": {
    "TABLE-NAME": "文宣管理",
    "ADD-ITEM": "新增問題",
    "EDIT-ITEM": "編輯問題",
    "ENABLE-ITEM": "啟用文宣圖",
    "DISABLE-ITEM": "停用文宣圖",
    "DELETE-ITEM": "刪除問題"
  },
  "COMPUTER": {
    "TABLE-NAME": "文宣管理",
    "ADD-ITEM": "新增電腦規格",
    "EDIT-ITEM": "編輯電腦規格",
    "ENABLE-ITEM": "啟用文宣圖",
    "DISABLE-ITEM": "停用文宣圖",
    "DELETE-ITEM": "刪除電腦規格"
  },
  "CONTENT": {
    "TABLE-NAME": "文宣管理",
    "ADD-ITEM": "新增文宣",
    "EDIT-ITEM": "編輯文宣",
    "ENABLE-ITEM": "啟用文宣圖",
    "DISABLE-ITEM": "停用文宣圖",
    "DELETE-ITEM": "刪除文宣"
  },
  "CONTENT-MANAGE": {
    "TABLE-NAME": "文宣管理",
    "ADD-ITEM": "新增文宣",
    "EDIT-ITEM": "編輯文宣",
    "ENABLE-ITEM": "啟用文宣圖",
    "DISABLE-ITEM": "停用文宣圖",
    "DELETE-ITEM": "刪除文宣圖"
  },
  MANAGERS: {
    "TABLE-NAME": "管理員管理",
    "ADD-ITEM": "新增管理員",
    "EDIT-ITEM": "編輯管理員",
    "ENABLE-ITEM": "啟用管理員圖",
    "DISABLE-ITEM": "停用管理員圖",
    "DELETE-ITEM": "刪除管理員",
    "PERMISSION-ITEM": "權限管理",
    CHOICEROLE: "選擇角色"
  },
  LOGINRECORD: {
    "TABLE-NAME": "管理員管理",
    "ADD-ITEM": "新增管理員",
    "EDIT-ITEM": "編輯管理員",
    "ENABLE-ITEM": "啟用管理員圖",
    "DISABLE-ITEM": "停用管理員圖",
    "DELETE-ITEM": "刪除登入記錄",
    "PERMISSION-ITEM": "權限管理"
  },
  ANNOUNCEMENT_TYPE: {
    "TABLE-NAME": "公告分類管理",
    "ADD-TYPE": "新增分類",
    "EDIT-TYPE": "編輯分類",
    "TYPE-NAME": "分類名稱",
    TOTAL: "文章數"
  },
  ANNOUNCEMENT: {
    "TABLE-NAME": "公告管理",
    "ADD-ANNOUNCEMENT": "新增公告",
    RELEASEDATE: "發布日期"
  },
  "WEB-MANAGE": {
    "GENERAL-SETTINGS": "一般設定",
    "MAINTENANCE-SETTINGS": "維護設定",
    "PERSONAL-SETTINGS": "個人網站設定",
    WEB_ICO: "網站標誌",
    WEB_NAME: "網站名稱",
    WEB_EMAIL: "網站E-mail",
    MAINTENANCE_SWITCH: "維護開關",
    MAINTENANCE_START_DATE: "維護開始日期",
    MAINTENANCE_START_TIME: "維護開始時間",
    MAINTENANCE_END_DATE: "維護結束日期",
    MAINTENANCE_END_TIME: "維護結束時間",
    AVATAR: "個人頭像",
    PERSONAL_NAME: "個人名稱",
    WEBURL: "網站網址",
    FAXNUMBER: "傳真號碼",
    PHONENUMBER: "電話號碼",
    LINEID: "Line ID",
    SERVICETIME: "服務時間",
    ADDRESS: "公司地址",
    WEBDESCRIPTION: "網站描述",
    GOOGLEANALYSIS: "Google Analysis",
    WEBKEYWORDS: "網站關鍵字",
    WEB_COUNTRY: "國家",
    WEB_AREA: "地區"
  },
  "IMAGE-MANAGE": {
    "TABLE-NAME": "圖片管理",
    "ADD-ITEM": "新增圖片",
    "ADD-FOLDER-ITEM": "新增資料夾",
    "DELETE-ITEM": "刪除圖片",
    TIMESWITCH: "設置上架時間"
  },
  "NEWS-CATEGORY": {
    "TABLE-NAME": "公告分類管理",
    "ADD-ITEM": "新增公告分類",
    "EDIT-ITEM": "編輯公告分類"
  },
  "MEMBER-TAG": {
    "TABLE-NAME": "標籤列表",
    "ADD-ITEM": "新增標籤",
    "EDIT-ITEM": "編輯標籤"
  },
  INDUSTRY: {
    "TABLE-NAME": "行業列表",
    "ADD-ITEM": "新增行業",
    "EDIT-ITEM": "編輯行業"
  },
  PROFESSION: {
    "TABLE-NAME": "職業列表",
    "ADD-ITEM": "新增職業",
    "EDIT-ITEM": "編輯職業"
  },
  ARTICLE: {
    UPDATEDATE: "最後更新時間",
    "TABLE-NAME": "文宣管理"
  },
  CONTACT: {
    "TABLE-NAME": "詢問類型管理",
    "ADD-CONTACT": "新增詢問類型",
    "CONTACT-NAME": "類型名稱"
  },
  HEADER: {
    LOGINRECORD: "登入記錄",
    DASHBOARD: "儀錶板",
    WEBMANAGE: "網站管理",
    CAROUSELMANAGE: "輪播管理",
    HOMECAROUSELMANAGE: "首頁輪播",
    NEWSMANAGE: "公告管理",
    NESCATEGORY: "公告分類",
    WEBCONTENT: "網站文宣",
    CONTENTMANAGE: "網站文宣管理",
    CONTACTUS: "聯絡我們",
    QUESTIONTYPE: "詢問類型",
    SYSTEMMANAGE: "系統管理",
    OTHERMANAGE: "其他管理",
    WEBINFO: "基本訊息",
    MAILSERVER: "郵件服務",
    SOCIALLOGIN: "社群登入",
    MEMBERMANAGE: "會員管理",
    MANAGER: "管理人員",
    OPERATERECORD: "操作記錄",
    MANAGERS: "管理員",
    index: "顯示",
    show: "查詢",
    update: "更新",
    store: "新增",
    destroy: "刪除",
    WEBSETTING: "網站設定",
    SITEMANAGE: "網站基本資訊",
    IMAGEMANAGE: "圖片管理",
    NEWSCATEGORY: "公告分類",
    HOME: "官網",
    HOMEMANAGE: "活動首頁管理",
    COMPUTERFORMAT: "電腦規格",
    COMPUTERFORMATMANAGE: "電腦規格總管",
    DEALERHOMEMANAGE: "經銷商首頁管理",
    COMMUNITYFORTUM: "社群論壇總管",
    COMMUNITYFORTUMMANAGE: "社群論壇管理",
    PRODUCTMANAGE: "產品",
    PRODUCT: "產品",
    PRODUCTLIST: "商品介紹總管",
    PRODUCTCATEGORY: "產品分類",
    MEMBER: "會員",
    MEMBERLIST: "會員總管",
    TAGCATEGORY: "會員標籤總管",
    SYSTEM: "系統",
    CAROUSEL: "輪播管理",
    CURRENCYSETTINGS: "幣別設定",
    SITE: "網站文宣",
    GOLDFLOWMANAGE: "金流設定",
    COMMODITYLIST: "產品總管",
    PACKAGECOMMODITYLIST: "商品價格總管",
    COMMODITYIMPORT: "產品匯入",
    ROLEMANAGE: "角色管理",
    ROLE: "角色管理",
    FAQMANAGE: "常見問題總管",
    COMPUTERSTANDARD: "電腦規格總管",
    TECHNOLOGY: "技術",
    TECHNOLOGYCATEGORY: "技術分類總管",
    TECHNOLOGYSUPPORT: "技術支援總管",
    TECHNOLOGYDISCUSSFORUM: "討論專區總管",
    TECHNOLOGYDISCUSSFORUMCOMMENT: "討論專區回覆",
    REPORT: "報表",
    SALEREPORT: "銷售報表",
    REFERREPORT: "經銷商報表",
    COMMODITY: "商品",
    COMMODITYCATEGORY: "分類總管",
    DEALERMANAGE: "經銷商總管",
    WAITERMANAGE: "服務員總管",
    REFERSALERECORD: "推薦銷售紀錄",
    REFERMEMBERRECORD: "推薦會員紀錄",
    APPLY: "報名",
    APPLYMANAGE: "報名總管",
    APPLYRECORD: "報名紀錄",
    DISCOUNT: "優惠",
    DISCOUNTMANAGE: "優惠碼總管",
    DISCOUNTRECORD: "優惠碼使用紀錄",
    ORDER: "訂單",
    ORDERMANAGE: "訂單總管",
    YETORDER: "未完成訂單",
    SUBSCRIPTIONMANAGE: "訂閱總管",
    DEVICEVALID: "裝置連線紀錄",
    MEMBERTAG: "會員標籤總管",
    CURRENYSETTINGS: "幣別設定",
    SEOMANAGE: "SEO 設定",
    FAQ: "常見問題",
    FAQCATEGORY: "常見問題分類總管",
    FAQCATEGORYMANAGE: "常見問題分類總管",
    EMAILTEMPLATE: "Email 模板設定",
    SERVICESMANAGE: "客服設定",
    EMAILMANAGE: "Email 伺服器設定",
    EMAIL: "信件總管",
    EMAILLOG: "寄件紀錄",
    HOMESTATUS: "首頁設定",
    PRODUCTTAG: "商品標籤管理",
    MANAGERSTATUS: "管理員狀態管理",
    INDUSTRYMANAGE: "行業管理",
    PROFESSIONMANAGE: "職業管理",
    COMMODITYSETTINGS: "免費天數設定",
    MEMBER_IMPORT: "會員匯入",
    ORDER_IMPORT: "訂單匯入",
    MEMBER_EXPORT: "會員匯出",
    DEALER_EXPORT: "經銷商匯出",
    IMPORT: "匯入",
    SEOKEY: "關鍵字設定",
  },
  FOOTER: {
    COMPANY: "鼎聚網路",
    ABOUT: "關於",
    CONTACT: "聯絡我們",
    TEAM: "團隊"
  },
  TOPBAR: {
    NOTIFICATION_TITLE: "使用者通知",
    NOTIFICATION_ALERT: "通知",
    NOTIFICATION_EVENT: "事件",
    NOTIFICATION_RECORD: "紀錄"
  },
  QUICKUSER: {
    HELLO: "你好",
    TITLE: "個人資訊",
    SIGNOUT: "登出",
    MYPROFILE: "個人檔案",
    RECENT_NOTIFICATION: "近期通知"
  },
  TRANSLATOR: {
    SELECT: "选择你的语言"
  },
  MENU: {
    NEW: "新",
    ACTIONS: "行动",
    CREATE_POST: "创建新帖子",
    PAGES: "Pages",
    FEATURES: "特征",
    APPS: "应用",
    DASHBOARD: "仪表板"
  },
  AUTH: {
    GENERAL: {
      OR: "要么",
      SUBMIT_BUTTON: "提交",
      NO_ACCOUNT: "没有账号？",
      SIGNUP_BUTTON: "注册",
      FORGOT_BUTTON: "忘记密码",
      BACK_BUTTON: "背部",
      PRIVACY: "隐私",
      LEGAL: "法律",
      CONTACT: "联系"
    },
    LOGIN: {
      TITLE: "创建帐号",
      BUTTON: "签到"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "用戶名"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "顾客",
      CUSTOMERS_LIST: "客户名单",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  badge: "徽章",
  close: "關閉",
  dataIterator: {
    noResultsText: "未能找到匹配的記錄",
    loadingText: "正在載入項目..."
  },
  dataTable: {
    itemsPerPageText: "每頁行數",
    ariaLabel: {
      sortDescending: "降序排列",
      sortAscending: "升序排列",
      sortNone: "未排序",
      activateNone: "激活以刪除排序",
      activateDescending: "激活對降序進行排序",
      activateAscending: "激活以升序排序"
    },
    sortBy: "排序依據"
  },
  dataFooter: {
    itemsPerPageText: "每頁項數",
    itemsPerPageAll: "全部",
    nextPage: "下一頁",
    prevPage: "上一頁",
    firstPage: "首頁",
    lastPage: "最後一頁",
    pageText: "{2} 中的 {0}-{1}"
  },
  datePicker: {
    itemsSelected: "已選擇{0}",
    nextMonthAriaLabel: "下個月",
    nextYearAriaLabel: "明年",
    prevMonthAriaLabel: "上個月",
    prevYearAriaLabel: "上一年"
  },
  noDataText: "無可用數據",
  carousel: {
    prev: "上一個",
    next: "下一個",
    ariaLabel: {
      delimiter: "{1}的輪播幻燈片{0}"
    }
  },
  calendar: {
    moreEvents: "{0} more"
  },
  fileInput: {
    counter: "{0}個文件",
    counterSize: "{0}個文件（共{1}個"
  },
  timePicker: {
    am: "上午",
    pm: "下午"
  },
  pagination: {
    ariaLabel: {
      wrapper: "分頁導航",
      next: "下一頁",
      previous: "上一頁",
      page: "轉到頁面{0}",
      currentPage: "當前頁，第{0}頁"
    }
  },
  YUP: {
    mixed: {
      default: '${path} 無效',
      required: '${path} 必須填寫',
      oneOf: '${path} 必須是以下值之一: ${values}',
      notOneOf: '${path} 不能是以下值之一: ${values}',
      defined: '${path} 必須定義',
      notType: '${path} 必須是正確格式'
    },
    string: {
      length: '${path} 字元長度必須為 ${length}',
      min: '${path} 必須至少為 ${min} 個字符',
      max: '${path} 必須最多為 ${max} 個字符',
      matches: '${path} 必須匹配以下內容："${regex}"',
      email: '${path} 必須是一個有效的電子郵件',
      url: '${path} 必須是一個有效的 URL',
      uuid: '${path} 必須是有效的 UUID',
      trim: '${path} 必須是修剪過的字符串',
      lowercase: '${path} 必須是小寫字符串',
      uppercase: '${path} 必須是大寫字符串'
    },
    number: {
      min: '${path} 必須大於或等於 ${min}',
      max: '${path} 必須小於或等於 ${max}',
      lessThan: '${path} 必須小於 ${less}',
      moreThan: '${path} 必須大於 ${more}',
      positive: '${path} 必須是正數',
      negative: '${path} 必須是負數',
      integer: '${path} 必須是整數'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {
      isValue: '${path} field must be ${value}'
    },
    object: {
      noUnknown: '${path} field has unspecified keys: ${unknown}'
    },
    array: {
      min: '${path} 至少需${min}個項目',
      max: '${path} field must have less than or equal to ${max} items',
      length: '${path} must be have ${length} items'
    }
  },
  SEO: {
    HEADER: "SEO IN HEADER",
    BODY: "SEO IN BODY",
    title: '標題',
    keyword: '關鍵字',
    content: "描述"
  },
  EMAIL: {
    TEMPLATE: {
      HEADER: "頁首文字",
      HEADER_IMG: "頁首圖片",
      FOOT: "頁尾文字",
      FOOT_IMG: "頁尾圖片",
      SUBJECT: "信件主旨",
      CONTENT: "信件內容",
    },
    SYSCONFIG: {
      DOMAIN: "主機域名",
      PORT: "主機端口",
      ENCRYPT: "主機端口類型",
      USERNAME: "帳號",
      PASSWORD: "密碼",
      FROM: "寄件者",
      TESTSEND: "寄送測試信件",
      PLACEHOLDER: "輸入信件",
      ATTENION: "請以,區分寄信者Ex:abc＠gmail.com,def＠gmail.com",
      TESTMAILSENDED: "測試信件已送出",
    },
    LOG: {
      TO: "收件者",
      SUBJECT: "信件標題",
      CONTENT: "信件內容",
      SENDTIME: "寄件時間"
    }
  },
  SERVICES: {
    CUSTOMERSERVICE: "客服專線",
    FB: "Facebook Messager 網址",
    LINE: "LINE 客服網址",
    WECHAT: "WeChat 客服網址",
    QQ: "QQ 客服網址",
    YOUTUBE: "Youtube 網址",
    TWITTER: "Twitter 網址",
    LINKEDIN: "Linkedin 網址",
    WHATSAPP: "Whatsapp 網址",
    FACEBOOK: "Facebook 網址",
  },
};
