import { createStore } from 'vuex'

import auth from "./auth.module";
import profile from "./profile.module";
import htmlclass from "./htmlclass.module";
import product from "./product.module";
import technology from "./technology.module";
import image from "./image.module";
import geo from "./geo.module";
import common from "./common.module";

const store = createStore({
  modules: {
    auth,
    profile,
    htmlclass,
    product,
    technology,
    image,
    geo,
    common,
  }
});

export default store;
