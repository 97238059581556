import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service";

// action types
export const PRODUCTLIST = "productList";
export const INDUSTRY = "industry";
export const PROFESSION = "profession";
export const WEB = "web";
export const CURRENCY_LIST = "currencyList";

// mutation types
export const SET_CURRENCYLIST = "setCurrencyList";
export const SET_INDUSTRY = "setIndustry";
export const SET_PROFESSION = "setProfession";

const state = {
  lang: i18nService.getActiveLanguage(),
  languages: i18nService.languages,
  industry: [],
  profession: [],
  currencyList: [],
  apps: [
    {
      id: "line",
      name: "Line",
      sort: 1,
    },
    {
      id: "wechat",
      name: "微信",
      sort: 2,
    },
    {
      id: "whatapp",
      name: "whatApp",
      sort: 0,
    },
  ]
};

const getters = {
  getLang(state) {
    return state.lang;
  },
  getLangList(state) {
    return state.languages;
  },
  getCurrencyList(state) {
    return state.currencyList;
  },
  getIndustry(state) {
    return state.industry;
  },
  getProfession(state) {
    return state.profession;
  },
  getApp(state) {
    if(i18nService.getActiveLanguage()=="en") {
      return state.apps.sort(function(a,b){
        if ( a.sort < b.sort){
          return -1;
        }
        if ( a.sort > b.sort){
          return 1;
        }
        return 0;
      })
    }
    return state.apps;
  }
};

const actions = {
  [WEB](context) {
    return new Promise((resolve) => {
      ApiService.query("/api/v1/Web", {
        params: {
          lang: state.lang,
          info: "7f6b9ad3-acb5-4a0c-bbaf-50b2fa677de8",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            context.commit(SET_INDUSTRY, response.data.data.industry);
            context.commit(SET_PROFESSION, response.data.data.profession);
          }
          resolve(response);
        })
        .catch((response) => {
          CommonService.AxiosHandle(response);
        });
    });
  },
  [CURRENCY_LIST](context) {
    return new Promise((resolve) => {
      ApiService.get("/Admin/v1/currency/online")
        .then((response) => {
          if (response.status == 200) {
            context.commit(SET_CURRENCYLIST, response.data.data);
          }
          resolve(response);
        })
        .catch((response) => {
          CommonService.AxiosHandle(response);
        });
    });
  },
};

const mutations = {
  [SET_INDUSTRY](state, data) {
    state.industry = data;
  },
  [SET_PROFESSION](state, data) {
    state.profession = data;
  },
  [SET_CURRENCYLIST](state, data) {
    state.currencyList = data.filter(item=>item.status ==1 );
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
