import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
// import router from "@/router";

// action types
export const COUNTRY = "country";
export const PROVINCE = "province";
export const CITY = "city";

export const SET_COUNTRY = "setCountry";
export const SET_PROVINCE = "setProvince";
export const SET_CITY = "setCity";

const state = {
  country: {},
  province: {},
  city: {},
};

const getters = {
  getCountry(state) {
    return state.country
  },
  getProvince(state) {
    return state.province
  },
  getCity(state) {
    return state.city
  },
};

const actions = {
  [COUNTRY](context) {
    if(Object.keys(state.country).length == 0) {
      return new Promise((resolve) => {
        ApiService.query("/api/v1/Geo", {
          params: {
            type: "country"
          },
        })
          .then((response) => {
            context.commit(SET_COUNTRY,response.data.data);
            resolve(response);
          })
          .catch((response) => {
            CommonService.AxiosHandle(response);
          });
      });
    } 
  },
  [PROVINCE](context,id) {
    if(typeof(state.province[id]) == 'undefined') {
      return new Promise((resolve) => {
        ApiService.query("/api/v1/Geo", {
          params: {
            type: "province",
            id: id
          },
        })
          .then((response) => {
            context.commit(SET_PROVINCE, {
              id: id,
              data: response.data.data
            });
            resolve(response);
          })
          .catch((response) => {
            CommonService.AxiosHandle(response);
          });
      });      
    }
  },
  [CITY](context,id) {
    if(typeof(state.city[id]) == 'undefined') {
      return new Promise((resolve) => {
        ApiService.query("/api/v1/Geo", {
          params: {
            type: "city",
            id: id
          },
        })
          .then((response) => {
            context.commit(SET_CITY, {
              id: id,
              data: response.data.data
            });
            resolve(response);
          })
          .catch((response) => {
            CommonService.AxiosHandle(response);
          });
      });
    }
  },
};

const mutations = {
  [SET_COUNTRY](state, data) {
    state.country = data;
  },
  [SET_PROVINCE](state, data) {
    state.province[data.id] = data.data;
  },
  [SET_CITY](state, data) {
    state.city[data.id] = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
