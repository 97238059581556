import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import CommonService from "@/core/services/common.service";
import i18n from "@/core/plugins/vue-i18n";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_INFO = "setInfo";
export const SET_FREE = "setFree";

const state = {
  errors: null,
  user: {},
  user_account_info: {
    LoginRecord: [],
    user: {
      name: "",
      email: "",
      username: "",
      uuid: "",
    }
  },
  free: 0,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentUserAccountInfo(state) {
    return state.user_account_info;
  },
  getFree(state) {
    return state.free;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve,reject) => {
      ApiService.post("/Admin/Admin/Login", credentials)
        .then((response) => {
          if (response.status == 200) {
            context.commit(SET_AUTH, response);
            resolve(response);
          }else{
            reject(response);
          }
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.get("/Admin/Admin/Logout")
        .then((response) => {
          context.commit(PURGE_AUTH);
          resolve(response);
        })
        .catch((response) => {
          CommonService.AxiosHandle(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise( (resolve,reject) => {
      if (JwtService.getToken()) {
        ApiService.get("/Admin/Admin/Check")
          .then((response) => {
            if (response.status == 200) {
              context.commit(SET_AUTH, response);
              context.commit(SET_INFO, response);
              context.commit(SET_FREE, response);
              resolve(response);
            }else{
              context.commit(PURGE_AUTH);
              reject(response);
            }
          });
      } else {
        reject(false);
      }
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FREE](state, data) {
    state.free = data.data.data.free;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.data.data.user;
    state.errors = {};
    if(typeof user.data.data.token != "undefined" && user.data.data.token != JwtService.getToken()){
      JwtService.saveToken(user.data.data.token);  
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_INFO](state, user_info) {
    let LoginRecord = [];
    user_info.data.data.LoginRecord.map(record => {
      LoginRecord.push({
        title: i18n.global.t("COMMON.LOGINRECORD"),
        desc: "IP: " + record.ip_address,
        time: CommonService.TimeFormat(record.login_at),
        color: "success",
        svg: process.env.BASE_URL + "media/svg/icons/Home/Library.svg"
      });
    });
    let OperateRecord = [];
    user_info.data.data.OperateRecord.map(record => {
      OperateRecord.push({
        title: i18n.global.t("HEADER.OPERATERECORD"),
        desc: `[${record.event}]${record.auditable_type}`,
        time: CommonService.TimeFormat(record.created_at),
        color: "success",
        svg: process.env.BASE_URL + "media/svg/icons/Home/Library.svg"
      });
    });
    user_info.data.data.LoginRecord = LoginRecord;
    user_info.data.data.OperateRecord = OperateRecord;
    state.user_account_info = user_info.data.data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
