import ApiService from "@/core/services/api.service";
// import i18n from "@/core/plugins/vue-i18n";

// action types
export const TECHNOLOGY_CATEGORY = "TechnologyCategory";

// mutation types
export const SET_TECHNOLOGY_CATEGORY = "setTechnologyCategory";

const state = {
  TechnologyCategory: [],
};

const getters = {
  getTechnologyCategory(state) {
    return state.TechnologyCategory;
  }
};

const actions = {
  [TECHNOLOGY_CATEGORY](context) {
    return new Promise(resolve => {
      ApiService.query(
        "/Admin/v1/TechnologyCategory",
        {
          params: {}
        }
      ).then(response => {
        if (response.status == 200) {
          context.commit(SET_TECHNOLOGY_CATEGORY, response.data.data);
          resolve(response);
        }
      });
    });
  },
};

const mutations = {
  [SET_TECHNOLOGY_CATEGORY](state, data) {
    state.TechnologyCategory = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
