const i18nService = {
  defaultLanguage: "tw",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg",
      value: "en",
      text: "English"
    },
    {
      lang: "tw",
      name: "繁體中文",
      flag: process.env.BASE_URL + "media/svg/flags/202-taiwan.svg",
      value: "tw",
      text: "繁體中文"
    },
    {
      lang: "cn",
      name: "簡體中文",
      flag: process.env.BASE_URL + "media/svg/flags/202-taiwan.svg",
      value: "cn",
      text: "簡體中文"
    }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  }
};

export default i18nService;
