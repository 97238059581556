import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
// import i18n from "@/core/plugins/vue-i18n";

// action types
export const PRODUCT_CATEGORY = "ProductCategory";

// mutation types
export const SET_PRODUCT_CATEGORY = "setProductCategory";

const state = {
  ProductCategory: [],
};

const getters = {
  getProductCategory(state) {
    return state.ProductCategory;
  }
};

const actions = {
  [PRODUCT_CATEGORY](context) {
    return new Promise(resolve => {
      ApiService.query(
        "/Admin/v1/ProductCategory",
        {
          params: CommonService.SortHandleObject({
            page: 1,
            itemsPerPage: -1,
            sortBy: ["sort"],
            sortDesc: []
          })
        }
      ).then(response => {
        if (response.status == 200) {
          context.commit(SET_PRODUCT_CATEGORY, response.data.data);
          resolve(response);
        }
      });
    });
  },
};

const mutations = {
  [SET_PRODUCT_CATEGORY](state, data) {
    data.map(categroy => {
      categroy.children = categroy.sub;
      state.ProductCategory.push(categroy);
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
