import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
// action types
export const GET_ACCOUNT_MENU = "getAccountMenu";

// mutation types
export const SET_MENU = "setMenu";

const state = {
  menu: {}
};

const getters = {
  getMenu(state) {
    return state.menu;
  }
};

const actions = {
  [GET_ACCOUNT_MENU](context) {
    return new Promise(resolve => {
      ApiService.get("/Admin/Route").then((response)=>{
        if(response.status==200){
          context.commit(SET_MENU, response.data.data);
          resolve(response);
        }
      })
      .catch(( response ) => {
        CommonService.AxiosHandle(response);
      });
    });
  }
};

const mutations = {
  [SET_MENU](state, menu) {
    state.menu = menu;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
