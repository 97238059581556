// action types
export const SHOW_PAGE_LOADING = "showPageLoading";
export const HIDE_PAGE_LOADING = "hidePageLoading";
export const START_LOADINGBAR = "startLoadingbar";
// mutation types
export const SET_PAGELOADING = "setPageLoading";
export const SET_LOADINGBAR = "setLoadingbar";

export default {
  state: {
    PageLoading: true,
    loadingbar: 0
  },
  getters: {
    PageLoading(state) {
      return state.PageLoading;
    },
    getLoadingbar(state) {
      return state.loadingbar;
    }
  },
  actions: {
    [SHOW_PAGE_LOADING](context) {
      context.commit(SET_PAGELOADING,true);
    },
    [HIDE_PAGE_LOADING](context) {
      context.commit(SET_PAGELOADING,false);
    },
    [START_LOADINGBAR](context,width) {
      context.commit(SET_LOADINGBAR,width);
    },
  },
  mutations: {
    [SET_PAGELOADING](state, status) {
      state.PageLoading = status;
    },
    [SET_LOADINGBAR](state, width) {
      state.loadingbar = width;
    },
  }
};
