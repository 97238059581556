import 'mdb-vue-ui-kit/css/mdb.min.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "@/core/services/store";
import i18n from "@/core/plugins/vue-i18n";
import CKEditor from '@ckeditor/ckeditor5-vue';

createApp(App)
.use(router)
.use(store)
.use(i18n)
.use(CKEditor)
.mount('#app')
