import { createI18n } from 'vue-i18n';

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as ch } from "@/core/config/i18n/ch";
import { locale as tw } from "@/core/config/i18n/tw";
import { setLocale } from 'yup';


let messages = {};
messages = { ...messages, en, ch, tw };
const lang = localStorage.getItem("language") || "tw";

setLocale(messages[lang].YUP);

const i18n = createI18n({
    legacy: true,
    locale: lang,
    messages: messages
  });

export default i18n;
